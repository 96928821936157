import React, { useEffect } from 'react'
import { Form } from 'antd'
import authActions from '../../store/modules/authActions'
import { getText, getTextServerError } from '../../lang'
import { myPasswordValidator } from '../../utils'
import MyDefaultForm from '../../components/MyDefaultForm/MyDefaultForm'
import { notifyError, notifySuccess } from '../../utils/Notify'
import { useLayout } from '../../layout/LayoutProvider/LayoutProvider'
import { SUBMIT_BTN } from '../../devOptions'
import MyDefaultPageLayout from '../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultInputPassword from '../../components/MyDefaultInputPassword/MyDefaultInputPassword'
import MyDefaultBlock from '../../components/MyDefaultBlock/MyDefaultBlock'

const ProfileChangePassword = ({ popupUpdate, onSavePopup }) => {
  const { setPageTitle, setFooterButtons } = useLayout() || {}

  const [form] = Form.useForm()

  useEffect(() => {
    if (!popupUpdate) {
      setPageTitle(getText('TEXT_PROFILE_CHANGE_PASSWORD'))
      setFooterButtons([
        {
          type: SUBMIT_BTN,
        },
      ])
    }
  }, [])

  const onFinish = async (values) => {
    values.isManualUpdate = Boolean(popupUpdate)
    const result = await authActions.setPassword(
      values.newPassword,
      values.confirmPassword,
      values.oldPassword,
      values.isManualUpdate
    )
    if (result.success) {
      if (result.data.token) {
        authActions.storeAuthData(
          result.data.user,
          result.data.token,
          authActions.getUnreadMessages(),
          authActions.getMyMessagesInboxUnreadMessages()
        )
        onSavePopup?.()
      }
      notifySuccess(getText('NTF_SUCCESS_SAVE_PASSWORD'))
    } else {
      onSavePopup?.(true)
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      onSavePopup?.(true)
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <MyDefaultForm
        form={form}
        name={popupUpdate && 'popupFormSubmit'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          newPassword: '',
          confirmPassword: '',
          oldPassword: '',
        }}
      >
        <MyDefaultBlock>
          <MyDefaultInputPassword
            isForm
            name='oldPassword'
            label={getText('WORD_OLD_PASSWORD')}
            errorMessage={`${getText('MSG_PLEASE_INPUT_PASSWORD')}!`}
            required
          />
          <MyDefaultInputPassword
            isForm
            name='newPassword'
            label={getText('WORD_NEW_PASSWORD')}
            errorMessage={`${getText('MSG_PLEASE_INPUT_PASSWORD')}!`}
            autoComplete='new-password'
            required
            additionalRules={[
              {
                validator: myPasswordValidator,
              },
            ]}
          />
          <MyDefaultInputPassword
            isForm
            name='confirmPassword'
            label={getText('WORD_CONFIRM_PASSWORD')}
            errorMessage={`${getText('MSG_PLEASE_CONFIRM_YOUR_PASSWORD')}!`}
            passwordToConfirm='newPassword'
            required
            isLast
          />
        </MyDefaultBlock>
      </MyDefaultForm>
    </MyDefaultPageLayout>
  )
}

export default ProfileChangePassword
