import React from 'react'
import './styles/'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import userflow from 'userflow.js'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { store, persistor } from './store'
import { isDebugMode } from './utils'
import { SocketProvider } from './utils/socket'

if (!isDebugMode) {
  // console.log = () => {};
  // console.error = () => {};
  // console.warn = () => {};
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        unmask: ['.unmask-me'],
      }),
    ],
    environment: process.env.REACT_APP_ENV,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    enabled:
      window.location.hostname !== 'localhost' ||
      window.location.hostname !== 'stgmatadorai',
  })
}

if (process.env.REACT_APP_USERFLOW_TOKEN) {
  userflow.init(process.env.REACT_APP_USERFLOW_TOKEN)
  // userflow.setResourceCenterLauncherHidden(true)
}

const root = createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SocketProvider>
        <App />
      </SocketProvider>
    </PersistGate>
  </Provider>
)

serviceWorker.register()
