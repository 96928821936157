import React from 'react'
import { Form } from 'antd'

const MyDefaultForm = ({
  form,
  size = 'middle',
  name = 'formSubmit',
  initialValues,
  onFinish,
  onFinishFailed,
  validateTrigger,
  className,
  onValuesChange,
  children,
  fullWidth,
  maxWidth,
}) => {
  return (
    <Form
      form={form}
      size={size}
      name={name}
      style={{
        width: fullWidth ? '100%' : 'unset',
        maxWidth: maxWidth ? 625 : 'auto',
      }}
      initialValues={initialValues || {}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      scrollToFirstError
      validateTrigger={validateTrigger || ['onBlur']}
      layout='vertical'
      className={`my_default_form ${className || ''}`}
      onValuesChange={onValuesChange}
    >
      {children}
    </Form>
  )
}

export default MyDefaultForm
