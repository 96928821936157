import React, { useState } from 'react'
import { Flex } from 'antd'
import SVGHamburgerFilterIcon from '../../icons/SVG/SVGHamburgerFilterIcon.js'
import MyDefaultButton from '../MyDefaultButton/MyDefaultButton'
import MyDefaultSearchInput from '../MyDefaultSearchInput/MyDefaultSearchInput'
import MyDefaultSelect from '../MyDefaultSelect/MyDefaultSelect'
import MyDefaultDrawer from '../MyDefaultDrawer/MyDefaultDrawer'
import {
  BUTTON,
  DATEPICKER,
  DROPDOWN_BUTTON,
  NEGATIVE_BUTTON,
  POPOVER,
  POSITIVE_BUTTON,
  PRIMARY,
  RANGEPICKER,
  SEARCH,
  SELECT,
  SELECT_LOCATION,
} from '../../devOptions.js'
import { MyDefaultDropdownButton } from '../MyDefaultDropdownButton/MyDefaultDropdownButton.js'
import MyDefaultNegativeButton from '../MyDefaultButton/MyDefaultNegativeButton/MyDefaultNegativeButton.js'
import MyDefaultPositiveButton from '../MyDefaultButton/MyDefaultPositiveButton/MyDefaultPositiveButton.js'
import useDeviceCheck from '../../utils/useDeviceCheck.js'
import MyDefaultRangePicker from '../MyDefaultRangePicker/MyDefaultRangePicker.js'
import './MyDefaultPageHeader.scss'
import MyDefaultDatePicker from '../MyDefaultDatePicker/MyDefaultDatePicker.js'
import MyDefaultPopover from '../MyDefaultPopover/MyDefaultPopover.js'
import MyDefaultSelectLocation from '../MyDefaultSelect/MyDefaultSelectLocation.js'

const HeaderItem = ({ item, placement }) => {
  if (item.type === SEARCH) {
    return (
      <MyDefaultSearchInput
        value={item.value}
        onChange={item.onChange}
        className={'my_default_page_header_item'}
        fullWidth
      />
    )
  }
  if (item.type === SELECT) {
    return (
      <MyDefaultSelect
        color={item.color || 'white'}
        options={item.options}
        value={item.value}
        onChange={item.onChange}
        onChangeItem={item.onChangeItem}
        onClear={item.onClear}
        placeholder={item.placeholder}
        className={'my_default_page_header_item'}
      />
    )
  }
  if (item.type === SELECT_LOCATION) {
    return (
      <MyDefaultSelectLocation
        color={item.color || 'white'}
        value={item.value}
        onSelectItem={item.onChange}
        onClear={item.onClear}
        placeholder={item.placeholder}
        className={'my_default_page_header_item'}
      />
    )
  }
  if (item.type === BUTTON) {
    return (
      <MyDefaultButton
        typeButton={item.typeButton || PRIMARY}
        buttonText={item.title}
        tooltip={Boolean(item.tooltipTitle)}
        tooltipTitle={item.tooltipTitle}
        onClick={item.onClick}
        disabled={item.disabled}
        icon={item.icon}
        className={'my_default_page_header_item'}
      />
    )
  }
  if (item.type === NEGATIVE_BUTTON) {
    return (
      <MyDefaultNegativeButton
        buttonText={item.title}
        onClick={item.onClick}
        disabled={item.disabled}
        className={'my_default_page_header_item'}
      />
    )
  }
  if (item.type === POSITIVE_BUTTON) {
    return (
      <MyDefaultPositiveButton
        buttonText={item.title}
        onClick={item.onClick}
        disabled={item.disabled}
        className={'my_default_page_header_item'}
      />
    )
  }
  if (item.type === DROPDOWN_BUTTON) {
    return (
      <MyDefaultDropdownButton
        title={item.title}
        dropdownMenuItems={item.items}
        onItemClick={item.onItemClick}
        onClick={item.onClick}
        className={'my_default_page_header_item'}
      />
    )
  }
  if (item.type === RANGEPICKER) {
    return (
      <MyDefaultRangePicker
        value={item.value}
        onChange={item.onChange}
        className={'my_default_page_header_item'}
        color={'white'}
        placement={placement || undefined}
      />
    )
  }
  if (item.type === DATEPICKER) {
    return (
      <MyDefaultDatePicker
        value={item.value}
        onChange={item.onChange}
        className={'my_default_page_header_item'}
        color={'white'}
        placement={placement || undefined}
        maxDate={item.maxDate}
      />
    )
  }
  if (item.type === POPOVER) {
    return (
      <MyDefaultPopover
        content={item.content}
        triggerPopover={item.triggerPopover}
        placement={item.placement}
        className={'my_default_page_header_item'}
      />
    )
  }
  return null
}

const MyDefaultPageHeaderAdvanced = ({ onClearAll, left, right, isDrawerExist }) => {
  const [visibleDrawer, setvisibleDrawer] = useState(false)
  const { isTabletPortraitOrMobile } = useDeviceCheck()

  return (
    <Flex className={`my_default_page_header_advanced`}>
      <Flex className='my_default_page_header_advanced--left' justify='start'>
        {(left || []).map((item, index) => {
          if (item.isInDrawer && isTabletPortraitOrMobile) {
            return null
          }
          return <HeaderItem key={index} item={item} />
        })}
        {Boolean(isTabletPortraitOrMobile && isDrawerExist) && (
          <MyDefaultButton
            className={'my_default_drawer_button'}
            icon={<SVGHamburgerFilterIcon color={'var(--white)'} />}
            onClick={() => {
              setvisibleDrawer(true)
            }}
          />
        )}
      </Flex>
      <Flex className='my_default_page_header_advanced--right' justify='end'>
        {(right || []).map((item, index) => {
          return <HeaderItem key={index} item={item} />
        })}
      </Flex>
      <MyDefaultDrawer
        placement={'right'}
        closable={false}
        onClose={() => {
          setvisibleDrawer(false)
        }}
        open={visibleDrawer}
        width={305}
        onClearAll={() => {
          onClearAll && onClearAll()
        }}
      >
        {[...(left || []), ...(right || [])].map((item, index) => {
          if (!item.isInDrawer) return null
          return <HeaderItem key={index} item={item} placement={'right'} />
        })}
      </MyDefaultDrawer>
    </Flex>
  )
}

export default MyDefaultPageHeaderAdvanced
